<template>
  <div id="ChangePWD">
    <el-form
      status-icon
      :rules="rules"
      :model="ruleForm"
      ref="ruleForm"
      label-width="80px"
      class="ruleForm"
    >
      <el-form-item label="旧密码" prop="oldPWD">
        <el-input type="password" v-model="ruleForm.oldPWD" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="pass">
        <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPass">
        <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item class="submitBtn">
        <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  export default {
    name: 'ChangePWD',
    data() {
      const validateOldPass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入旧密码'));
        } else {
          callback();
        }
      };
      const validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入新密码'));
        } else {
          if (this.ruleForm.checkPass !== '') {
            this.$refs.ruleForm.validateField('checkPass');
          }
          callback();
        }
      };
      const validateNewPass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.ruleForm.pass) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      return {
        ruleForm: {
          oldPWD: '',
          pass: '',
          checkPass: '',
        },
        rules: {
          oldPWD: [{ validator: validateOldPass, trigger: 'blur' }],
          pass: [{ validator: validatePass, trigger: 'blur' }],
          checkPass: [{ validator: validateNewPass, trigger: 'blur' }],
        },
      };
    },
    methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$store.commit('setLoading', true);
            this.$store
              .dispatch('admin/changePWD', {
                old_password: this.ruleForm.oldPWD,
                new_password: this.ruleForm.pass,
                confirm_password: this.ruleForm.checkPass,
              })
              .then((res) => {
                this.$MSG.success(res.message);
                this.ruleForm.oldPWD = '';
                this.ruleForm.pass = '';
                this.ruleForm.checkPass = '';
              })
              .catch(this.$autoCatch)
              .finally(() => {
                this.$store.commit('setLoading', false);
              });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
    },
  };
</script>

<style lang="scss">
  #ChangePWD {
    @media screen and(max-width:768px) {
      display: flex;
      justify-content: center;
    }
    .el-input {
      width: 200px;
    }

    .submitBtn {
      .el-form-item__content {
        margin-left: 120px !important;
      }
    }
  }
</style>
